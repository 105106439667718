import { Component, OnInit } from '@angular/core';

import { Title } from '@angular/platform-browser';

import { TdDigitsPipe } from '@covalent/core/common';
import { TdLoadingService } from '@covalent/core/loading';

// import { UserService, IUser } from '../users';

// import { AlertsService } from '../../services';

// import { multi } from './data';

@Component({
  selector: 'qs-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  // viewProviders: [ AlertsService ],
})
export class DashboardComponent implements OnInit {

  // Current date
  year: any = new Date().getFullYear();

  // items: Object[];
  // // users: IUser[];
  // products: Object[];
  // alerts: Object[];

  // Chart
  // single: any[];
  // multi: any[];

  view: any[] = [700, 400];

  // options
  // showXAxis: boolean = true;
  // showYAxis: boolean = true;
  // gradient: boolean = false;
  // showLegend: boolean = false;
  // showXAxisLabel: boolean = true;
  // xAxisLabel: string = '';
  // showYAxisLabel: boolean = true;
  // yAxisLabel: string = 'Sales';

  // colorScheme: any = {
  //   domain: ['#1565C0', '#2196F3', '#81D4FA', '#FF9800', '#EF6C00'],
  // };

  // line, area
  // autoScale: boolean = true;

  constructor(private _titleService: Title,
              // private _alertsService: AlertsService,
              private _loadingService: TdLoadingService) {
                // Chart
                // this.multi = multi.map((group: any) => {
                //   group.series = group.series.map((dataItem: any) => {
                //     dataItem.name = new Date(dataItem.name);
                //     return dataItem;
                //   });
                //   return group;
                // });
  }

  ngOnInit(): void {
    this._titleService.setTitle( 'ClaroTech :: ODS Viewer' );
    this._loadingService.register('items.load');
  }

  // ngx transform using covalent digits pipe
  axisDigits(val: any): any {
    return new TdDigitsPipe().transform(val);
  }
}
