import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MainComponent } from './main.component';
import { DashboardComponent } from './dashboard/dashboard.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      { component: DashboardComponent, path: '', },
      { path: '', loadChildren: './orgs/orgs.module#OrganisationModule', },
      { path: '', loadChildren: './capability/capability.module#CapabilityModule', },
    ],
  },
];

export const appRoutes: any = RouterModule.forRoot(routes, { enableTracing: false });
// export const appRoutes: any = RouterModule.forRoot(routes, { enableTracing: true });
